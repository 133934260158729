<template>
  <div class="m_professional">
    <m-title :title="`${year}年度专业科目`" />
    <div class="card_panel">
      <div class="panel">
        <div class="value">{{ Statistics.keChengShu }}</div>
        <div class="label">考试总数</div>
      </div>
      <div class="panel">
        <div class="value">{{ Statistics.daiKaoShi }}</div>
        <div class="label">待考试</div>
      </div>
      <div class="panel">
        <div class="value">{{ Statistics.kaoShiTongGuo }}</div>
        <div class="label">考试通过</div>
      </div>
    </div>
    <m-title title="专业课在线考试" />
    <div class="courseList">
      <capsule-tab @click="selectCapsule" v-model="selectTab" :list="List" />
      <div class="list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <div class="item-left">
              <div
                class="circle"
                :style="{
                  border:
                    item.status == 1 && item.ifQualified == 0
                      ? '3px solid #B50413'
                      : '3px solid #B5BCC9',
                }"
              ></div>
              <div style="margin: auto 10px">
                <div class="title">{{ item.courseName }}</div>
                <div class="achievement">考试成绩：{{ item.testScore }}分</div>
              </div>
            </div>
            <div
              @click="handlerExamination(item)"
              :class="
                item.status == 1 && item.ifQualified == 0 ? '' : 'disabled-link'
              "
            >
              <div class="button">去考试</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import MTitle from "@/components/m_title.vue";
import CapsuleTab from "@/components/capsuleTab.vue";
import {
  getCoursepPublicDemandAccount,
  getCoursepUserCourseList,
} from "@/api/specializedController";
import * as storage from "@/utils/storage";

export default {
  name: "m_professional",
  components: {
    CapsuleTab,
    MTitle,
  },
  data() {
    return {
      loading: false,
      finished: false,
      dataList: [],
      selectTab: 0,
      List: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "未完成",
          value: 1,
        },
        {
          label: "待考试",
          value: 2,
        },
        {
          label: "考试通过",
          value: 3,
        },
      ],
      filterType: 0,
      Statistics: {},
    };
  },
  computed: {
    yearId() {
      return storage.getStore("yearId");
    },
    year() {
      return storage.getStore("year") || "";
    },
  },
  activated() {
    this.handleRefresh();
    // this.inspectRefresh(this.handleRefresh);
  },
  methods: {
    handlerExamination(item) {
      this.keepAliveNavigation(`/examination?courseId=${item.studyCourseId}`);
    },
    handleRefresh() {
      this.getStatistics();
    },
    // 获取统计
    getStatistics() {
      const that = this;
      getCoursepPublicDemandAccount({
        trainingPlanId: this.yearId,
      }).then((res) => {
        that.Statistics = res.data;
      });
    },
    // 获取专业课列表信息
    onLoad() {
      getCoursepUserCourseList({
        trainingPlanId: this.yearId,
        filterType: this.filterType,
        type: 0,
        ifExam: 1,
      }).then((res) => {
        this.dataList = res.data;
        this.loading = false;
        this.finished = true;
      });
    },
    selectCapsule(item, index) {
      console.log(item, index);
      this.filterType = item.value;
      this.loading = false;
      this.finished = false;
      this.dataList = [];
      this.onLoad();
    },
  },
};
</script>
<style scoped lang="scss">
.m_professional {
  height: 100%;
  width: 100%;

  .statistics {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin: 10px auto;

    .statistics_card {
      padding: 10px 15px;
      background: #b50413;
      border-radius: 10px;
      color: #ffffff;
    }

    .right {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .card_panel {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 15px auto;

    .panel {
      padding: 10px 12px;
      text-align: center;
      background: rgba(251, 154, 162, 0.2);
      border-radius: 8px;

      .label {
        font-size: 12px;
      }

      .value {
        font-size: 16px;
      }
    }
  }

  .courseList {
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 10px;
    margin: 10px auto;

    .list {
      .item-left {
        display: flex;
        align-items: center;
      }
      .item {
        padding: 12px 15px;
        background: #f4f6f8;
        border-radius: 4px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;

        .circle {
          width: 10px;
          height: 10px;
          min-width: 10px;
          min-height: 10px;
          border: 3px solid #b50413;
          border-radius: 10px;
        }
        .title {
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          font-size: 14px;
        }
        .achievement {
          color: #818496;
          font-size: 12px;
        }
        .button {
          background: rgba(181, 4, 19, 0.1);
          padding: 3px 10px;
          width: max-content;
          border-radius: 240px;
          color: #b50413;
        }
        .disabled-link {
          pointer-events: none;
          cursor: not-allowed;
          color: #ccc;
          text-decoration: none;
          .button {
            background: rgba(181, 188, 201, 0.2);
            padding: 3px 10px;
            width: max-content;
            border-radius: 240px;
            color: #b5bcc9;
          }
        }
      }
    }
  }
}
</style>
